<template>
  <div :class="{ 'is-child': depth != 0 }">
    <div
      :class="{ 'mx-0 mb-3': depth != 0 }"
      v-for="(item, index) in items"
      :key="`${depth}-${index}`"
    >
      <template
        v-if="
          (depth === 0 && isCurrentPage(item)) ||
          (depth > 0 && isActive(item) && isCurrentPage(item))
        "
      >
        <template v-if="item.ticket_field_id === 7">
          <div class="input-action admin">
            <InputText
              :textFloat="''"
              :value="item.displayValue"
              :placeholder="item.label_admin"
              type="text"
              :isRequired="Boolean(item.is_require_submit)"
              :v="errors.$each.$iter[index].value"
              :isValidate="errors.$each.$iter[index].value.$error"
              :textValidate="item.validate_text_admin || undefined"
              readonly
              custom-label
              @inputClick="handleAssignTo(index, item.value)"
            >
              <template #textFloat>
                <div class="custom-label">
                  <label
                    >{{ item.label_admin }}
                    <span
                      v-if="Boolean(item.is_require_submit)"
                      class="text-danger"
                      >*</span
                    ></label
                  >

                  <b-form-checkbox
                    v-if="isShowAssignToMe"
                    v-model="isAssignTome"
                    checked="1"
                    unchecked-value="0"
                    @change="($event) => onAssignToMeChange($event, index)"
                    >Assign to me</b-form-checkbox
                  >
                </div>
              </template>
            </InputText>

            <div class="input-action-icon">
              <b-icon
                stacked
                icon="search"
                scale="0.5"
                variant="grey"
                @click="handleAssignTo(index, item.value)"
              ></b-icon>
            </div>
          </div>
        </template>
        <template v-else-if="item.ticket_field_id === 1">
          <div
            class="input-action"
            :class="[{ disabled: isCustomer || isEdit }]"
          >
            <InputText
              :textFloat="item.label_admin"
              :value="item.displayValue"
              :placeholder="item.label_admin"
              type="text"
              :isRequired="Boolean(item.is_require_submit)"
              :v="errors.$each.$iter[index].value"
              :isValidate="errors.$each.$iter[index].value.$error"
              readonly
              :disabled="isCustomer || isEdit"
              :textValidate="item.validate_text_admin || undefined"
              @inputClick="handleCustomer(index, item.value)"
            />
            <div class="input-action-icon">
              <b-icon
                stacked
                icon="search"
                scale="0.5"
                variant="grey"
                @click="handleCustomer(index, item.value)"
              ></b-icon>
            </div>
          </div>
        </template>
        <template v-else-if="item.field_type_id === 1">
          <InputText
            :textFloat="item.label_admin"
            v-model="item.value"
            :placeholder="item.label_admin"
            :type="
              item.field_condition.length &&
              item.field_condition[0].value === 'number'
                ? 'number'
                : 'text'
            "
            :isRequired="Boolean(item.is_require_submit)"
            :v="errors.$each.$iter[index].value"
            :isValidate="errors.$each.$iter[index].value.$error"
            :textValidate="item.validate_text_admin || undefined"
            :disabled="isEdit && [2, 3].includes(item.ticket_field_id)"
          />
        </template>
        <template v-else-if="item.field_type_id === 2">
          <label :for="item.label_admin"
            >{{ item.label_admin }}
            <span
              v-if="Boolean(item.is_require_submit)"
              class="text-danger ml-1"
              >*</span
            >
          </label>

          <b-form-checkbox-group
            :id="`${depth}-${item.label_admin}-${item.id}`"
            :name="`${depth}-${item.label_admin}-${item.id}`"
            v-model="item.value"
            :options="item.field_choice"
            value-field="id"
            text-field="name"
            class="ml-4 mb-3"
            stacked
            @change="selectedIndex = index"
          ></b-form-checkbox-group>
          <div v-if="errors.$each.$iter[index].value.$error">
            <span class="text-error">{{
              item.validate_text_admin || "Please Select."
            }}</span>
          </div>
        </template>
        <template v-else-if="item.field_type_id === 3">
          <div class="mb-3">
            <label class="main-label">
              {{ item.label_admin
              }}<span
                v-if="Boolean(item.is_require_submit)"
                class="text-danger ml-1"
                >*</span
              ></label
            >

            <multiselect
              :close-on-select="item.field_type_id === 4 ? true : false"
              :clear-on-select="false"
              v-model="item.value"
              :options="item.field_choice"
              :placeholder="item.label_admin"
              track-by="id"
              label="name"
              :multiple="item.field_type_id === 4 ? false : true"
              :class="[errors.$each.$iter[index].value.$error ? 'error' : '']"
              @select="selectedIndex = index"
              :disabled="
                (isEdit && [3, 6, 2].includes(item.ticket_field_id)) ||
                (isEdit && status == 4 && item.ticket_field_id == 5)
              "
            >
              <template v-slot:option-first>
                <b-form-select-option :value="null"
                  >-- Select {{ item.label_admin }} --</b-form-select-option
                >
              </template>
            </multiselect>
            <div v-if="errors.$each.$iter[index].value.$error">
              <span class="text-error">{{
                item.validate_text_admin || "Please Select."
              }}</span>
            </div>
          </div>
        </template>
        <template v-else-if="item.field_type_id === 4">
          <InputSelect
            :title="item.label_admin"
            :name="item.label_admin"
            :isRequired="Boolean(item.is_require_submit)"
            v-model="item.value"
            v-bind:options="item.field_choice"
            valueField="id"
            textField="name"
            :v="errors.$each.$iter[index].value"
            :isValidate="errors.$each.$iter[index].value.$error"
            :textValidate="item.validate_text_admin || undefined"
            :disabled="
              (isEdit && [3, 6, 2].includes(item.ticket_field_id)) ||
              (isEdit && status == 4 && item.ticket_field_id == 5)
            "
            @onDataChange="
              (val) => {
                selectedIndex = index;
                handleOnChange(item, val);
              }
            "
          >
            <template v-slot:option-first>
              <b-form-select-option :value="null"
                >-- Select {{ item.label_admin }} --</b-form-select-option
              >
            </template>
          </InputSelect>
        </template>

        <template v-else-if="item.field_type_id === 6">
          <InputSelect
            :title="item.label_admin"
            :name="item.label_admin"
            :isRequired="Boolean(item.is_require_submit)"
            v-model="item.value"
            v-bind:options="item.branch_list || []"
            valueField="id"
            textField="name"
            :v="errors.$each.$iter[index].value"
            :isValidate="errors.$each.$iter[index].value.$error"
            :textValidate="item.validate_text_admin || undefined"
            class="mt-3"
          >
            <template v-slot:option-first>
              <b-form-select-option :value="null"
                >-- Select {{ item.label_admin }} --</b-form-select-option
              >
            </template>
          </InputSelect>
        </template>
        <template v-else-if="item.field_type_id === 5">
          <b-row class="mb-3">
            <b-col>
              <label class="label"
                >{{ item.label_admin
                }}<span
                  v-if="Boolean(item.is_require_submit)"
                  class="text-danger ml-1"
                  >*</span
                ></label
              >
              <div
                :class="[
                  errors.$each.$iter[index].value.$error
                    ? 'border-error input-container'
                    : 'input-container',
                ]"
              >
                <datetime
                  type="date"
                  class="date-picker"
                  :input-style="styleDatetime"
                  v-model="item.value"
                  placeholder="DD/MM/YYYY"
                  format="dd/MM/yyyy"
                  value-zone="Asia/Bangkok"
                  :ref="`${item.id}-datetime`"
                >
                </datetime>
                <div
                  :class="'icon-primary text-right'"
                  @click="$refs[`${item.id}-datetime`][0].isOpen = true"
                >
                  <font-awesome-icon
                    icon="calendar-alt"
                    :class="'pointer color-primary'"
                    color="#B41BB4"
                  />
                </div>
              </div>
              <div v-if="errors.$each.$iter[index].value.$error">
                <span class="text-error">{{
                  item.validate_text_admin || "Please Select."
                }}</span>
              </div>
            </b-col>
            <b-col class="mt-auto">
              <div
                :class="[
                  errors.$each.$iter[index].value.$error
                    ? 'border-error input-container'
                    : 'input-container',
                ]"
              >
                <datetime
                  type="time"
                  class="date-picker"
                  :input-style="styleDatetime"
                  v-model="item.value"
                  placeholder="HH:MM"
                  format="HH:mm"
                  value-zone="Asia/Bangkok"
                  :ref="`${item.id}-time`"
                >
                </datetime>
                <div
                  :class="'icon-primary text-right'"
                  @click="$refs[`${item.id}-time`][0].isOpen = true"
                >
                  <font-awesome-icon
                    icon="calendar-alt"
                    :class="'pointer color-primary'"
                    color="#B41BB4"
                  />
                </div>
              </div>
              <div v-if="errors.$each.$iter[index].value.$error">
                <span class="text-error">{{
                  item.validate_text_admin || "Please Select."
                }}</span>
              </div>
            </b-col>
          </b-row>
        </template>
        <template v-else-if="item.field_type_id === 7">
          <b-row>
            <b-col>
              <label class="label"
                >{{ item.label_admin
                }}<span
                  v-if="Boolean(item.is_require_submit)"
                  class="text-danger ml-1"
                  >*</span
                ></label
              >
              <div
                :class="[
                  errors.$each.$iter[index].value.$error
                    ? 'border-error input-container'
                    : 'input-container',
                ]"
              >
                <datetime
                  type="date"
                  class="date-picker"
                  :input-style="styleDatetime"
                  v-model="item.value"
                  placeholder="DD/MM/YYYY"
                  format="dd/MM/yyyy"
                  value-zone="Asia/Bangkok"
                  :ref="`${item.id}-date`"
                >
                </datetime>

                <div
                  :class="'icon-primary text-right'"
                  @click="$refs[`${item.id}-date`][0].isOpen = true"
                >
                  <font-awesome-icon
                    icon="calendar-alt"
                    :class="'pointer color-primary'"
                    color="#B41BB4"
                  />
                </div>
              </div>
              <div v-if="errors.$each.$iter[index].value.$error">
                <span class="text-error">{{
                  item.validate_text_admin || "Please Select."
                }}</span>
              </div>
            </b-col>
          </b-row>
        </template>
        <template v-else-if="item.field_type_id === 8">
          <UploadFile
            v-model="item.value"
            :value="item.value"
            :textFloat="item.label_admin"
            :placeholder="item.label_admin"
            format="image"
            name="thumbnail"
            accept="image/jpeg, image/png"
            text="*Please upload only file .png, .jpg size 1:1  and less than 10 MB"
            :isRequired="Boolean(item.is_require_submit)"
            v-on:onFileChange="($event) => onImageChange($event, index)"
            :fileName="item.value"
            :isValidate="errors.$each.$iter[index].value.$error"
            :v="errors.$each.$iter[index].value"
            :textValidate="item.validate_text_admin || undefined"
          />
          <div @click="showImage(item.value)" v-if="item.value">
            <PreviewBox
              v-on:delete="onDeleteImage(index)"
              :disabled="item.isLoading"
              :showPreviewType="0"
              :showPreview="item.value"
              :isLoadingImage="item.isLoading"
              :ratioType="1"
              class="mb-3"
            />
          </div>
        </template>

        <template v-else-if="item.field_type_id === 12">
          <TextEditorsTiny
            v-if="!(item.ticket_field_id == 4 && isEdit)"
            :textFloat="item.label_admin"
            :rows="4"
            :value="item.value"
            :name="`${index}-${depth}-${item.name}`"
            placeholder="Type something..."
            :v="errors.$each.$iter[index].value"
            @onDataChange="(val) => (item.value = val)"
            :isRequired="Boolean(item.is_require_submit)"
            :isValidate="errors.$each.$iter[index].value.$error"
            :textValidate="item.validate_text_admin || undefined"
            :defaultEdit="isEdit"
          >
            <template v-slot:label-action>
              <b-button
                class="ml-2"
                size="sm"
                variant="custom-primary"
                @click="getTemplate"
              >
                Template
              </b-button>
            </template>
          </TextEditorsTiny>
        </template>

        <template v-else-if="item.field_type_id === 10">
          <div class="text-title my-3">{{ item.label_admin }}</div>
        </template>
        <template v-else-if="item.field_type_id === 11">
          <div class="text-sub-title my-3">{{ item.label_admin }}</div>
        </template>
        <template v-else-if="item.field_type_id === 13">
          <div class="my-3"></div>
        </template>

        <div
          v-if="item.field_dependent && item.field_dependent.length"
          class="mt-3"
        >
          <FormInputList
            :items="item.field_dependent"
            :depth="depth + 1"
            :parentValue="item.value"
            :errors="errors.$each.$iter[index].field_dependent"
            :currentPage="currentPage"
            class="mt-3"
          />
        </div>
      </template>
    </div>

    <ModalAdminList
      :ref="`${depth}-adminModal`"
      :selectedAdmin="selectedAdmin"
      @select="handleSelectedAdmin"
    />
    <ModalCustomerList
      :ref="`${depth}-customerModal`"
      @select="handleSelectedCustomer"
      :selectedCustomer="selectedCustomer"
    />
    <ModalTemplate :ref="`${depth}-modalTemplate`" />
    <ModalPreviewImage :ref="`${depth}-modalPreviewImage`" />
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";

import ModalAdminList from "@/components/ticket/activity/ModalAdminList";
import ModalCustomerList from "@/components/ticket/activity/ModalCustomerList";
import TextEditorsTiny from "@/components/inputs/TextEditorsTiny";
import UploadFile from "@/components/inputs/UploadFile";
import PreviewBox from "@/components/PreviewBox.vue";
import ModalPreviewImage from "@/components/modal/ModalPreviewImage";
import ModalTemplate from "@/components/ticket/activity/ModalTemplate";

export default {
  name: "FieldInputList",
  components: {
    Multiselect,

    ModalAdminList,
    ModalCustomerList,
    TextEditorsTiny,
    UploadFile,
    PreviewBox,
    ModalTemplate,
    ModalPreviewImage,
    FormInputList: () =>
      import("@/components/ticket/activity/FormInputList.vue"),
  },
  props: {
    items: {
      required: true,
      type: Array,
    },
    depth: {
      required: true,
      type: Number,
    },
    errors: {
      required: true,
      type: Object,
    },
    parentValue: {
      required: false,
    },
    isEdit: {
      required: false,
      type: Boolean,
    },
    isCustomer: {
      required: false,
      type: Boolean,
    },
    status: {
      required: false,
      type: Number | null,
      default: null,
    },
    currentPage: {
      required: false,
      type: Number,
      default: 1,
    },
    rawAdmin: {
      required: false,
      type: String,
    },
  },
  data() {
    return {
      styleDatetime: {
        width: "100%",
        border: "none",
      },
      selectedIndex: 0,

      selectedAdmin: null,
      selectedCustomer: null,
      isLoadingImage: false,
      isDisable: false,
      isAssignTome: false,
      adminData: {},
    };
  },
  watch: {
    parentValue: {
      handler(newVal) {
        if (this.depth > 0 && newVal) {
          this.items.forEach((element) => {
            element.isActive = this.isActive(element);
            if (element.field_dependent.length) {
              element.field_dependent.forEach((child) => {
                child.isActive = false;
              });
            }
          });
        }
      },
      immediate: true,
      deep: true,
    },
  },
  computed: {
    isShowAssignToMe() {
      if (!this.rawAdmin) return true;
      const currentAdminValue = this.getAdminValue();
      return currentAdminValue.value !== this.rawAdmin && this.rawAdmin;
    },
  },

  created() {
    this.getCurrentUser();
    if (this.dept == 0) this.totalPage = this.items[0].page;
  },

  methods: {
    getAdminValue() {
      return this.items.find((el) => el.ticket_field_id === 7);
    },
    isCurrentPage(item) {
      return item.page && item.page == this.currentPage;
    },

    showImage(imageUrl) {
      this.$refs[`${this.depth}-modalPreviewImage`].show(imageUrl);
    },
    getCurrentUser: async function () {
      const { data } = await this.axios.get(`/ticket/adminuserguid`);
      if (data.result == 1) {
        this.adminData = await this.$refs[
          `${this.depth}-adminModal`
        ].filterByGuid(data.detail);
      }
    },
    async onAssignToMeChange(value, index) {
      this.selectedIndex = index;
      if (value == 1) {
        this.handleSelectedAdmin(this.adminData, true);
      } else {
        this.items[this.selectedIndex].value = null;
        this.items[this.selectedIndex].displayValue = "";
        this.$forceUpdate();
      }
    },
    isActive(item) {
      const isList =
        this.parentValue &&
        this.parentValue.length &&
        this.parentValue[0] &&
        this.parentValue[0].id;

      return isList
        ? this.parentValue.map((el) => el.id).includes(item.depen_on_choice_id)
        : this.parentValue == item.depen_on_choice_id;
    },
    selectBranch(value, index) {
      this.items[index].value = value;
      this.$forceUpdate();
    },
    handleAssignTo(index, value) {
      this.selectedAdmin = value;
      this.selectedIndex = index;
      this.$refs[`${this.depth}-adminModal`].show();
    },
    handleCustomer(index, value) {
      if (this.isCustomer || this.isEdit) return;
      this.selectedIndex = index;
      this.selectedCustomer = value;
      this.$refs[`${this.depth}-customerModal`].show();
    },
    handleSelectedCustomer(customer) {
      this.items[this.selectedIndex].displayValue = [
        customer.name,
        customer.telephone,
      ]
        .filter((el) => el)
        .join(", ");
      this.items[this.selectedIndex].value = customer.user_guid;
      this.$forceUpdate();
    },
    handleSelectedAdmin(admin, isAssignTome = false) {
      this.items[this.selectedIndex].displayValue = [
        admin.name,
        admin.telephone,
      ]
        .filter((el) => el)
        .join(", ");
      this.items[this.selectedIndex].value = admin.user_guid;
      this.isAssignTome = isAssignTome;
      this.$forceUpdate();
    },
    onImageChange(img, index) {
      this.selectedIndex = index;
      this.items[this.selectedIndex].isLoading = true;
      var reader = new FileReader();
      reader.readAsDataURL(img);
      reader.onload = async () => {
        const images = await this.saveImagetoDb(reader.result);
        this.isLoadingImage = false;
        this.isDisable = false;
        this.items[this.selectedIndex].value = images;
        this.items[this.selectedIndex].isLoading = false;
        this.$forceUpdate();
      };
    },
    saveImagetoDb: async function (img) {
      var form = {
        Base64String: img,
      };
      await this.$store.dispatch("ActionUploadImage", form);
      var data = await this.$store.state.moduleConnectAPI.stateUploadImage;
      if (data.result == 1) {
        return data.detail;
      }
    },
    onDeleteImage(index) {
      this.items[index].value = null;
      this.$forceUpdate();
    },
    handleOnChange(value, $event) {
      if (value.ticket_field_id == 6) {
        let index = this.items.findIndex((el) => el.ticket_field_id == 3);
        let name = value.field_choice.find((el) => el.id == $event);
        if (index)
          if (name) {
            let rValue = this.items[index].value
              ? this.items[index].value.trim()
              : "";
            let dName = value.field_choice.find((el) => el.name == rValue);

            if (!this.items[index].value || dName)
              this.items[index].value = name.name;
          }
      }
    },
    getTemplate() {
      this.$refs[`${this.depth}-modalTemplate`].show();
    },
  },
};
</script>

<style lang="scss" scoped>
.input-action {
  position: relative;
  width: 100%;

  ::v-deep .input-custom input {
    cursor: pointer !important;
  }

  .input-action-icon {
    cursor: pointer;
    position: absolute;
    z-index: 1;
    top: 27px;
    right: 5px;
    width: 32px;
    height: 32px;
    background-color: inherit;
    color: var(--primary-color);
  }

  &.admin {
    .input-action-icon {
      top: 32px;
    }
  }

  &.disabled {
    pointer-events: none;
  }
}

::v-deep .date-picker .vdatetime-input {
  border: 1px solid #d8dbe0;
  border-radius: 0.25rem;
  margin-bottom: 0;
}

.border-error {
  border-color: red !important;
  border: 1px solid red;
  border-radius: 5px;
}

::v-deep .vdatetime-popup {
  top: 60% !important;
}

.icon-primary {
  flex: 1 1 auto;
  cursor: text;
}

::v-deep .text-editor-custom {
  &.error {
    .tox-tinymce {
      border: 1px solid red;
    }
  }
}

::v-deep .multiselect {
  &.error {
    .multiselect__tags {
      border: 1px solid red;
    }
  }
  .multiselect__tags {
    height: 35px;
    min-height: unset;
    padding: 5px 40px 0 8px;
  }
}

::v-deep .border-box-preview1-1 {
  max-width: 250px;
  max-height: 250px;
  width: 45%;

  .preview-box-image {
    object-fit: contain;
  }
}

.custom-label {
  display: flex;
  gap: 16px;
  align-items: center;
  margin-bottom: 5px;
}

.text-title {
  font-size: 24px;
  font-weight: bold;
}

.text-sub-title {
  font-size: 18px;
}
</style>
