<template>
  <b-modal v-model="isShow" centered title="Ticket Template">
    <InputSelect
      title="Ticket Template"
      name="ticket-template"
      isRequired
      v-model="template"
      v-bind:options="[]"
      valueField="id"
      textField="name"
    >
      <template v-slot:option-first>
        <b-form-select-option :value="null">
          -- Select Ticket Template --
        </b-form-select-option>
      </template>
    </InputSelect>
  </b-modal>
</template>

<script>
export default {
  data() {
    return {
      isShow: false,
      template: null,
    };
  },
  methods: {
    show() {
      this.isShow = true;
    },
    hide() {},
  },
};
</script>

<style></style>
